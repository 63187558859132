import "@styles/components/header.scss";

export default function Header() {
  return (
    <header className="header">
      <div className="header-desc">
        <img src="images/logo.png" alt="마이디" />
        <p>공동인증센터</p>
      </div>
      <div className="header-desc">
        <a href="https://myd.world">마이디 소개</a>
        <span>공동인증서 복사하기</span>
        <a href="https://www.youtube.com/watch?v=7pNqxANUe8M" target="_blank" rel="noreferrer">
          마이디백서
        </a>
        {/* <a href="https://myd.world/crowdworker" target="_blank" rel="noreferrer">
          크라우드워커
        </a> */}
        {/* <a href="https://myd.world/datamarket/" target="_blank" rel="noreferrer">
          데이터 거래
        </a> */}
      </div>
    </header>
  );
}
