import { IStep } from "@customTypes/Props";

import "@styles/components/step.scss";

export default function Step({ ...props }: IStep) {
  return (
    <div className="step">
      <div className="step-img">
        <img src={`images/step${props.step}.png`} alt={`step${props.step}`} className={`step${props.step}`} />
      </div>
      <div className="step-desc">
        <p>STEP {props.step}</p>
        <p>
          {props.description[0]}
          <br />
          {props.description[1]}
        </p>
      </div>
    </div>
  );
}
