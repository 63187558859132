import { useRef } from "react";
import { IPopup } from "@customTypes/Props";
import { isMobile } from "react-device-detect";
import "@styles/components/popup.scss";

export default function Popup({ isShowing, setIsShowing, domain, bodySize }: IPopup) {
  const popupBodyRef = useRef<HTMLDivElement>(null);

  const onLoadHandler = (e: React.SyntheticEvent<HTMLIFrameElement>) => {
    const pathName = e.currentTarget.contentWindow?.location.pathname.split("/")[2];
    if (pathName === "success.html") {
      popupBodyRef.current!.style.height = "415px";
    } else {
      popupBodyRef.current!.style.height = "316px";
    }
  };

  const onClickHandler = () => {
    setIsShowing(false);
  };

  if (isMobile) {
    return (
      <div className={isShowing ? "popup open-popup" : "popup"}>
        {isShowing ? (
          <div className="popup-body" style={{ width: "320px", height: "200px" }}>
            <div className="popup-header">
              <p style={{ fontSize: "14px", marginBottom: "20px" }}>공동 인증서 복사 프로그램 설치</p>
            </div>
            <p className="popup-desc">
              해당 프로그램은 모바일에서 사용할 수 없습니다 <br />
              PC에서 마이디 홈페이지로 접속하여 진행해주세요
            </p>
            <a className="close-btn" href="https://m.myd.world">
              확인
            </a>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div className={isShowing ? "popup open-popup" : "popup"} style={{ height: `${bodySize}px` }}>
      {isShowing ? (
        <div className="popup-body" ref={popupBodyRef}>
          <div className="popup-header">
            <p>공동 인증서 복사 프로그램 설치</p>

            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <p className="popup-close" onClick={onClickHandler}>
              닫기
            </p>
          </div>
          <iframe title="cert-relay" src={`https://${domain}/cert-relay`} onLoad={onLoadHandler} />
        </div>
      ) : null}
    </div>
  );
}
