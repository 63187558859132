import React, { useState, useEffect, useRef } from "react";

import { Header, Popup, Step } from "@components/index";

import "@styles/cert.scss";
import { isMobile } from "react-device-detect";

function App() {
  const [isShowing, setIsShowing] = useState(false);
  const [mockupInfo, setMockupInfo] = useState({ src: "", alt: "" });
  const [domain, setDomain] = useState("");
  const [bodySize, setBodySize] = useState(0);
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const type = window.location.hostname.split(".")[0];
    if (type === "relay") {
      setMockupInfo({
        alt: type,
        src: "images/mockup_prod.png",
      });
    } else {
      setMockupInfo({
        alt: type,
        src: "images/mockup.png",
      });
    }

    if (isMobile) {
      setIsShowing(true);
      document.body.style.overflow = "hidden";
    }
  }, []);

  const onClickHandler = () => {
    setIsShowing(!isShowing);
    setBodySize(rootRef.current!.clientHeight + 92);

    if (window.location.hostname.split(".")[0] === "relay") {
      setDomain("relay.cert.myd.world");
    } else {
      setDomain("next.snplab.io");
    }
  };

  return (
    <div ref={rootRef}>
      <Header />

      <article>
        <div className="main">
          <div className="main-desc">
            <p className="main-title">
              PC에서 마이디로 <br /> 공동인증서 복사하기
            </p>
            <p className="main-subtitle">
              복사할 공동인증서를 입력한 후 <br /> 마이디 앱에 표시된 12자리를 입력해주세요
            </p>
            <button type="button" onClick={onClickHandler}>
              인증서 복사하기
            </button>
          </div>
          <div>
            <img src={mockupInfo.src} alt={mockupInfo.alt} />
            <a href="https://www.youtube.com/watch?v=7pNqxANUe8M" target="_blank" rel="noreferrer">
              <img src="images/youtube.png" alt="youtube" />
            </a>
          </div>
        </div>

        <div className="main-info">
          <p className="info-header">공동인증서 복사하는 방법</p>

          <div className="step-container">
            <Step step={1} description={["위[복사하기] 버튼을 클릭하여", "인증서 복사프로그램을 실행합니다"]} />
            <Step step={2} description={["인증서 복사 프로그램에", "인증서 비밀번호를 입력합니다"]} />
            <Step step={3} description={["마이디 앱에 표시된", "인증번호 12자리를 입력합니다"]} />
          </div>
        </div>
      </article>

      {isShowing && <Popup isShowing={isShowing} setIsShowing={setIsShowing} domain={domain} bodySize={bodySize} />}
    </div>
  );
}

export default App;
